import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { withClientState } from 'apollo-link-state'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import defaults from '@/graphql/local/default-value'
import resolvers from '@/graphql/local/resolvers'
import typeDefs from '@/graphql/local/type-defs'
import env from '@/helpers/env'
import graphql from '@/services/graphql'
import i18n from '@/helpers/i18n'
import Notification from '@/services/notification'
import Authentification from '@/services/authentification'
import router from '@/helpers/router'
import { Consts } from '@/helpers/consts'
import Logger from '@/services/logger'
import Loader from '@/services/loader'

Vue.use(VueApollo)

const AUTH_TOKEN = 'apollo-token'
const httpEndpoint = env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'

const instance = createProvider()

export default {
  instance
}

function createProvider() {
  const cache = new InMemoryCache()
  const stateLink = withClientState({
    cache,
    defaults,
    resolvers,
    typeDefs
  })
  const httpLink = createHttpLink({ uri: httpEndpoint, fetch: graphql.graphQLFetcher })
  const errorLink = onError(async ({ graphQLErrors, networkError }) => {
    // eslint-disable-next-line no-console
    console.error(graphQLErrors, networkError)
    if (graphQLErrors) {
      // eslint-disable-next-line array-callback-return
      graphQLErrors.map(({ message, extensions, path }) => {
        let errorDisplayed = false
        if (extensions.data) {
          if (extensions.data.PasswordMismatch) {
            errorDisplayed = true
            Notification.showError(i18n.instance().t('change-password-error-notification'), 6000)
          } else if (extensions.data.DuplicateEmail) {
            errorDisplayed = true
            Notification.showError(i18n.instance().t('error-duplicate-email'), 6000)
          }
        }
        if (extensions.code) {
          const codes = extensions.codes ?? []
          codes.push(extensions.code)

          if (codes.includes('INVALID_LINKED_IN_URL')) {
            errorDisplayed = true
            Notification.showError(i18n.instance().t('error-linked-in-url'), 6000)
          } else if (codes.includes('EXISTING_USER_NOT_RECRUITER')) {
            errorDisplayed = true
            Notification.showError(i18n.instance().t('error-user-not-recruiter'), 8000)
            router.instance.push(i18n.getLocalizedUrl(Consts.URL_500))
          } else if (codes.includes('NO_NEED_TO_CONFIRM')) {
            errorDisplayed = true
            router.instance.push(i18n.getLocalizedUrl(Consts.URL_LOGIN))
          } else if (codes.includes('INVALID_CAST')) {
            if (path.indexOf('confirmEmail') !== -1) {
              errorDisplayed = true
            }
          } else if (codes.includes('CANDIDATE_HAS_ALREADY_APPLIED_FOR_COMPANY')) {
            errorDisplayed = true
            Notification.showError(i18n.instance().t('candidate-already-applied-error'), 8000)
          } else if (codes.includes('UNAUTHORIZED_ACCESS')) {
            Logger.logError(`GraphQL error - Unauthorized access :: ${JSON.stringify(graphQLErrors)}`)
            Authentification.logout()
          }
        }

        if (!errorDisplayed) {
          Notification.showError(i18n.instance().t('error-unexpected'), 6000)
          router.instance.push(i18n.getLocalizedUrl(Consts.URL_500))
          Logger.logError(`Unexpected GraphQL error :: ${JSON.stringify(extensions)}`)
        }
      })
    }

    if (networkError) {
      await Authentification.logout()
      Notification.showError(i18n.instance().t('error-unexpected'), 6000)
    }
  })

  const apolloClient = new ApolloClient({
    resolvers,
    link: ApolloLink.from([stateLink, errorLink, httpLink]),
    cache,
    defaultOptions: {
      httpEndpoint,
      tokenName: AUTH_TOKEN,
      persisting: false,
      websocketsOnly: false,
      ssr: false
    }
  })

  apolloClient.onResetStore(stateLink.writeDefaults)

  const vueApollo = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    watchLoading(isLoading, countModifier) {
      Loader.modifyCount(countModifier)
    }
  })

  return vueApollo
}
